@import "../../styles/utils/mixins";

.header {
  z-index: 10;
  display: flex;
  min-height: 40px;
  padding: 10px 20px;
  margin: 0 -20px 0 -20px;
  // margin: 0 auto;
  border-bottom: 1px solid #dfe2eb;
  background-color: #fff;

  @include from(tablet) {
    min-height: 50px;
    padding: 10px 20px;
  }
}
