@import "../../../styles//utils/mixins";

.wrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.name {
  font-family: "Nanum Brush Script";
  font-size: 30px;
  margin-left: 5px;
  font-weight: bold;

  @include from(tablet) {
    font-size: 40px;
    margin-left: 7px;
  }
}

.logo {
  width: 30px;

  @include from(tablet) {
    width: 35px;
  }
}
