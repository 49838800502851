@import "../../styles/utils/mixins";

.LoaderMain {
  display: flex;
  justify-content: center;
  margin-top: 30px;

  @include from(tablet) {
    margin-top: 50px;
  }
}
