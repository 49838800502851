@import "../../styles/utils/mixins";

.list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  max-width: 250px;
  max-height: 250px;
  width: calc(100% - 4 * 15px);
  margin: 10px;

  @media screen and(min-width: 425px) and(max-width:599px) {
    width: calc((100% - 4 * 15px) / 2);
  }

  @media screen and (min-width: 600px) and (max-width: 1199px) {
    width: calc((100% - 4 * 15px) / 3);
  }

  @media screen and (min-width: 1200px) {
    width: calc((100% - 90px) / 4);
  }
}

.button {
  width: 100%;
  height: 100%;

  padding: 0;
  border: none;
  background: none;
}

.image {
  width: 100%;
  object-fit: cover;
  border-radius: 5px;
  cursor: pointer;
}

.nameWrapper {
  position: absolute;
  cursor: pointer;
  display: flex;
  height: 40px;
  width: 97%;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  align-items: flex-end;
  justify-content: center;
  backdrop-filter: blur(1px);

  @include from(desktop) {
    height: 50px;
  }
}

.name {
  font-family: "Nanum Brush Script";
  font-size: 30px;
  font-weight: bold;

  @include from(tablet) {
    font-size: 40px;
  }
}

.nameLight {
  @extend .name;
  color: white;
}

.nameDark {
  @extend .name;
  color: black;
}

.notFoundMsg {
  font-family: "Nanum Brush Script";
  text-align: center;
  font-size: 30px;
  font-weight: bold;

  @include to(tablet) {
    font-size: 25px;
  }
}
