/* nanum-brush-script-regular - latin */
@font-face {
  font-family: "Nanum Brush Script";
  font-style: normal;
  font-weight: 400;
  src: url("../../fonts/nanum-brush-script-v17-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../../fonts/nanum-brush-script-v17-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../../fonts/nanum-brush-script-v17-latin-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../../fonts/nanum-brush-script-v17-latin-regular.woff")
      format("woff"),
    /* Modern Browsers */
      url("../../fonts/nanum-brush-script-v17-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../../fonts/nanum-brush-script-v17-latin-regular.svg#NanumBrushScript")
      format("svg"); /* Legacy iOS */
}
/* roboto-regular - latin_cyrillic-ext */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url("../../fonts/roboto-v29-latin_cyrillic-ext-regular.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../../fonts/roboto-v29-latin_cyrillic-ext-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../../fonts/roboto-v29-latin_cyrillic-ext-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../../fonts/roboto-v29-latin_cyrillic-ext-regular.woff")
      format("woff"),
    /* Modern Browsers */
      url("../../fonts/roboto-v29-latin_cyrillic-ext-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../../fonts/roboto-v29-latin_cyrillic-ext-regular.svg#Roboto")
      format("svg"); /* Legacy iOS */
}
/* roboto-500 - latin_cyrillic-ext */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  src: url("../../fonts/roboto-v29-latin_cyrillic-ext-500.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../../fonts/roboto-v29-latin_cyrillic-ext-500.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../../fonts/roboto-v29-latin_cyrillic-ext-500.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../../fonts/roboto-v29-latin_cyrillic-ext-500.woff") format("woff"),
    /* Modern Browsers */
      url("../../fonts/roboto-v29-latin_cyrillic-ext-500.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../../fonts/roboto-v29-latin_cyrillic-ext-500.svg#Roboto")
      format("svg"); /* Legacy iOS */
}
/* roboto-700 - latin_cyrillic-ext */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: url("../../fonts/roboto-v29-latin_cyrillic-ext-700.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../../fonts/roboto-v29-latin_cyrillic-ext-700.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../../fonts/roboto-v29-latin_cyrillic-ext-700.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../../fonts/roboto-v29-latin_cyrillic-ext-700.woff") format("woff"),
    /* Modern Browsers */
      url("../../fonts/roboto-v29-latin_cyrillic-ext-700.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../../fonts/roboto-v29-latin_cyrillic-ext-700.svg#Roboto")
      format("svg"); /* Legacy iOS */
}
