* {
  box-sizing: border-box;
}

body,
p,
ul,
ol {
  margin: 0;
  padding: 0;
}

body {
  font-family: "Roboto";
}

img {
  display: block;
  width: 100%;
  height: 100%;
}

a {
  text-decoration: none;
}
