@import "../../styles/utils/mixins";

.label {
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 30px 0 20px 0;
  margin: 0 auto;
  font-size: 14px;
  line-height: 1.2;
  z-index: 8;
}

.input {
  padding: 5px 15px;
  width: 70%;
  height: 40px;
  color: #52555f;
  border-width: 1px;
  border-radius: 5px;
  border-color: #52555f;
  border-style: solid;
  outline: none;
  z-index: 8;

  max-width: 1200px;
}
