@import "../../styles/utils/mixins";

.table {
  margin: 0 auto;
}

.buttonHead {
  cursor: pointer;
  padding: 10px 20px;
  max-width: 130px;
  height: 44px;
  border: 1px solid rgb(190, 190, 190);
  background-color: transparent;
  border-radius: 22px;
  font-weight: 400;
  font-size: 12px;
  text-transform: uppercase;
  color: rgba(82, 85, 95, 0.7);
  background-color: #fff;

  display: flex;
  align-items: center;
  margin: 0 auto;

  &:hover,
  &:focus {
    border: 1px solid #be5ac3;
    color: #be5ac3;
  }
}

.priceHead {
  width: 120px;
}

.ascending::after {
  content: "👇";
  display: inline-block;
  margin-left: 1em;
}

.descending::after {
  content: "☝️";
  display: inline-block;
  margin-left: 1em;
}

table {
  border-collapse: collapse;
  border-radius: 20px;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;

  max-width: 1280px;
}

table caption {
  font-size: 1.5em;
  margin: 0.5em 0 0.75em;
}

table tr {
  border: 1px solid rgb(190, 190, 190);
  padding: 0.35em;
}

table th,
table td {
  padding: 0.625em;
  border: 1px solid rgb(190, 190, 190);
  text-align: center;
}

table th {
  font-size: 0.85em;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  background-color: #f5f6fb;
}

@media screen and (max-width: 600px) {
  table {
    border: 0;
  }

  table caption {
    font-size: 1.3em;
  }

  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  table tr {
    border: 1px solid rgb(190, 190, 190);
    display: block;
    margin-bottom: 0.625em;
  }

  table td {
    border: 1px solid rgb(190, 190, 190);
    display: block;
    font-size: 0.8em;
    text-align: right;
  }

  table td::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  table td {
    @include to(tablet) {
      position: relative;
      padding: 10px 10px 10px 90px;
    }

    &::before {
      position: absolute;
      left: 10px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

table a {
  color: #bc45c2;
}
